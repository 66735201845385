<template>
  <div>
    <div class="row mb-4">
      <div class="col-sm-12 mb-4">
        <div class="card text-left">
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6">
                 <div class="breadcrumb mb-2 custom-top-padding float-left">
                  <h1>Re Consulta</h1>
                </div>
                <router-link :to="{ name: 'facturacion-index' }">
                  <button
                    class="ml-3 btn btn-white border border-light rounded">
                    Regresar
                  </button>
              </router-link>
              </div>
              <div class="col-sm-6">
                <div class="text-right">
                  <label class="switch switch-success mr-3">
                    <input type="checkbox" id="filter"
                     :checked="estado"
                     v-model="estado"
                     @change="listarRegistros">
                    <span class="slider"></span>
                    <span>{{ labelActivos }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row mb-1">
              <div class="col-sm-3">
                <div class="btn-group">
                  <span class="pt-2 mr-1">Mostrar </span>
                  <b-form-select v-model="por_pagina"
                   :options="pagina_opciones"
                   @change="listarRegistros">
                  </b-form-select>
                  <span class="pt-2 ml-1"> Registros</span>
                </div>
              </div>
              <div class="col-sm-3 offset-sm-6 text-right">
                <div class="form-group row">
                  <div class="col-sm-4"></div>
                  <div class="col-sm-8">
                    <input type="text" class="form-control"
                     v-model="busqueda"
                     autocomplete="off"
                     placeholder="Buscar"
                     @keyup="filtrarBusqueda">
                  </div>
                </div>
              </div>
            </div>
            <b-table
              class="table table-striped table-hover"
              :fields="fields"
              :items="listaTabla"
              :busy="isLoading"
              :per-page="por_pagina"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <div class="align-middle spinner-bubble spinner-bubble-danger mr-5"></div>
                  <strong>Cargando...</strong>
                </div>
              </template>
              <template #cell(numero)="items">
                {{ items.item.numero_venta }}
              </template>
                <template #cell(f_venta)="items">
                {{items.item.fecha_venta}}
                </template>
                <template #cell(medico_tratante)="items">
                  {{ items.item.medico }}
                </template>
                <template #cell(accion)="data">
                  <button
                  :class="['btn', data.item.fecha_reconsulta != null ? 'btn-success' : 'btn-secondary', 'py-1', 'px-2']"
                  @click="openModalCambiarTurno(data.item)"
                  :disabled="isVencido(data.item.tiempo)"
                >
                  Agendar
                </button>
                </template>
                <template #cell(tiempo)="items">
                  {{ getTiempo(items.item.tiempo) }}
                </template>
                  <template #cell(estado_id)="items">
                    <button :class="getEstadoColor(items.item.tiempo)" class="btn no-cursor" style="color: white">
                      {{ getEstadoNombre(items.item.tiempo) }}
                    </button>
                  </template>
            </b-table>
            <div class="row">
              <div class="col-sm-8"></div>
              <div class="col-sm-4">
                <b-pagination
                  v-model="pagina_actual"
                  :total-rows="totalFilas"
                  :per-page="por_pagina"
                  @input="listarRegistros"
                  class="custom-pagination my-0 float-right"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalCambiarTurno
      v-if="modalCambiarTurno"
      v-model="modalCambiarTurno"
      :itemSeleccionado="itemSeleccionado"
      :isReconsulta="isReconsulta"
      @onUpdateTurno="listarRegistros"
    />
    <ModalTurno
      ref="modalTurnoReconsulta"
      :id="'reconsulta'"
      :itemSeleccionado="this.itemSeleccionado"
      :isReconsulta="isReconsulta"
      :medicoSelected="medicoSelected"
      :editMode=false
      :isTurnoManualFromEdit="false"
      @onUpdateTurno="listarRegistros"
      />
  </div>
</template>

<script>
import axios from '@/modules/common/axiosERP';
import { util } from '@/plugins/util';
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import ModalCambiarTurno from '../components/ModalCambiarTurno.vue';
import ModalTurno from '../components/ModalTurno.vue';

export default {
  name: 'indexReconsulta',
  components: {
    ModalCambiarTurno,
    ModalTurno,
  },
  created() {
    this.listarRegistros();
  },
  data() {
    return {
      isLoading: true,
      estado: true,
      busqueda: null,
      pagina_actual: 1,
      por_pagina: 50,
      pagina_opciones: [50, 100],
      listaTabla: [],
      totalFilas: 0,
      delayTimeout: null,
      listaEstado: [
        {
          id: 1,
          nombre: 'A tiempo',
          color: 'bg-success', // Verde
        },
        {
          id: 2,
          nombre: 'Vencido',
          color: 'bg-danger', // Rojo
        },
        {
          id: 3,
          nombre: 'Atendido',
          color: 'bg-warning', // Amarillo
        },
      ],
      fields: [
        {
          key: 'numero', label: 'Registro', class: 'text-center', thClass: 'th-custom-orange', thStyle: { width: '5%' }, sortable: true,
        },
        {
          key: 'f_venta', label: 'F/Venta', class: 'text-center', thClass: 'th-custom-orange', thStyle: { width: '7%' },
        },
        {
          key: 'paciente', label: 'Paciente', class: 'text-center', thClass: 'th-custom-orange', thStyle: { width: '17%' }, sortable: true,
        },
        {
          key: 'celular', label: 'Celular', class: 'text-center', thClass: 'th-custom-orange', thStyle: { width: '7%' }, sortable: true,
        },
        {
          key: 'medico_tratante', label: 'Medico Tratante', class: 'text-center', thClass: 'th-custom-orange', thStyle: { width: '18%' }, sortable: true,
        },
        {
          key: 'accion', label: 'Accion', class: 'text-center px-0', thClass: 'th-custom-orange', thStyle: { width: '5%' },
        },
        {
          key: 'tiempo', label: 'Tiempo', class: 'text-center', thClass: 'th-custom-orange', thStyle: { width: '7%' },
        },
        {
          key: 'estado_id', label: 'Estado', class: 'text-center', thClass: 'th-custom-orange', thStyle: { width: '9%' },
        },
      ],
      itemSeleccionado: {},
      modalCambiarTurno: false,
      modalTurno: false,
      estadoSelected: {
      },
      isReconsulta: false,
      isActiveReconsulta: false,
      medicoSelected: null,
    };
  },
  methods: {
    filtrarBusqueda() {
      const DELAY = 400;
      clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(this.listarRegistros, DELAY);
    },
    async openModalCambiarTurno(item) {
      this.itemSeleccionado = item;
      this.isReconsulta = true;
      if (this.itemSeleccionado.venta_detalle_turno_id === null) {
        this.medicoSelected = await this.getMedicoSelected(item);
        this.modalTurno = true;
        this.modalCambiarTurno = false;
        this.$bvModal.show(`modal-turno-${'reconsulta'}`);
      } else {
        this.modalCambiarTurno = true;
        this.modalTurno = false;
      }
    },
    getMedicoSelected(item) {
      return {
        id: item.medico_id,
        nombre: item.medico,
      };
    },
    formatDate(fecha) {
      if (!fecha) return '';
      const [year, month, day] = fecha.split('-');
      return `${day}/${month}/${year}`;
    },
    getEstadoId(tiempoEstado) {
      if (tiempoEstado > 0) {
        return 1;
      }
      if (tiempoEstado < 0) {
        return 2;
      }
      return 3;
    },
    getTiempo(tiempoEstado) {
      if (tiempoEstado > 0) {
        return `${tiempoEstado} dias`;
      }
      if (tiempoEstado < 0) {
        return '';
      }
      return 'Hoy';
    },
    isVencido(tiempo) {
      return this.getEstadoId(tiempo) === 2;
    },
    getEstadoNombre(tiempoEstado) {
      const id = this.getEstadoId(tiempoEstado);
      // eslint-disable-next-line no-shadow
      const estado = this.listaEstado.find((estado) => estado.id === id);
      return estado ? estado.nombre : '';
    },
    getEstadoColor(tiempoEstado) {
      const id = this.getEstadoId(tiempoEstado);
      // eslint-disable-next-line no-shadow
      const estado = this.listaEstado.find((estado) => estado.id === id);
      return estado ? estado.color : '';
    },
    async listarRegistros() {
      this.isLoading = true;
      try {
        const params = {
          state: this.estado,
          filterKey: this.busqueda,
          page: this.pagina_actual,
          per_page: this.por_pagina,
        };
        const RESPONSE = await axios.get('/clinic/invoicing/reconsulta', { params });
        this.listaTabla = RESPONSE.data.data.reconsultas.data;
        this.totalFilas = RESPONSE.data.data.reconsultas.total[0].total;
      } catch (error) {
        util.showNotify(error.response.data.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed:
  {
    ...mapFields('main', [
      'extrafacturaElectronica',
    ]),

    ...mapGetters('main', [
      'getFuncionalidades',
      'hasPermisionNuevoVentas',
      'hasPermisionModificarVentas',
      'hasPermisionVerVentas',
      'hasPermisionEliminarVentas',
      'hasPermisionImprimirVentas',
      'hasPermissionMedicoTercero',
      'hasPermisionTurnoMedico',
      'isBloqueadoReConsulta',
      'hasPermisionListarConsultaExterna',
    ]),
    labelActivos() {
      if (this.estado) {
        return 'Activos';
      }
      return 'Resueltos';
    },
  },
};
</script>

<style>
.custom-top-padding {
  padding-top: 5.53px;
}
.th-custom-orange {
  background-color: #edbf69;
}

.custom-pagination .page-item.active .page-link {
  background-color: #edbf69 !important; /* Color de fondo del botón activo */
  border-color: #edbf69 !important; /* Color del borde del botón activo */
  color: black !important; /* Color del texto del botón activo */
  z-index: 1;
}
.no-cursor {
  pointer-events: none;
}
</style>
